import "./styles/main.scss";
import { preloadImages } from "./script/utils.js";
import { Content } from "./script/content.js";
import { langInit } from "./script/lang.js";
import { gsap, ScrollTrigger } from "gsap/all";
import Lenis from "@studio-freight/lenis";

import magrini1 from "./assets/img/1.jpg";
import magrini2 from "./assets/img/2.jpg";

document.addEventListener("DOMContentLoaded", function () {
  langInit();

  document.getElementById("year").innerHTML = new Date().getFullYear();

  // Smooth scrolling.

  const initSmoothScrolling = () => {
    const lenis = new Lenis({
      duration: 0.2,  
      easing: (t) => t,  
    });

 
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(1000, 16);
  };

  // .content elements
  const contentElems = [...document.querySelectorAll(".contentImage")];
  contentElems.forEach((el) => new Content(el));

  // smooth scrolling with Lenis
  initSmoothScrolling();

  // Preload images then remove loader (loading class) from body
  preloadImages(".canvas-wrap").then(() =>
    document.body.classList.remove("loading")
  );

  // TEXT RANDOM EFFECT ------------------------------------------

  
var glitchTexts = [];
var possibleChar =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789/;[]=-~!@#$%^&*()+{}:?><€¡¥×«»¶¿çµñ©æáßðøöóíúüþéåä";
var glitchStrength = 20; // how many characters should be converted PER GLITCH (in percentage)
var glitchSpeed = 20; // how many times per second should the effect be applied
var glitchDuration = 500; // how long the effect should last (in milliseconds)
var glitchLeave = false; // if the effect should last when you don't hover the item anymore
var timeouts = [];

document.querySelectorAll(".glitch-item").forEach(function (element, index) {
  var txt = element?.textContent?.trim();
  glitchTexts.push(txt);
});

function applyGlitch(element) {
  var originalText = glitchTexts[element.index - 1];
  var times = glitchSpeed * (glitchDuration / 1000);
  var charArr = element.textContent.trim().split("");
  var strength = Math.ceil(charArr.length / (100 / glitchStrength));
  for (var i = 0; i < times; i++) {
    var speed = 1000 / glitchSpeed;
    timeouts.push(
      setTimeout(function () {
        for (var j = 0; j < strength; j++) {
          var randChar = possibleChar.charAt(
            Math.floor(Math.random() * possibleChar.length)
          );
          var indexOfChar = Math.floor(Math.random() * charArr.length);
          charArr[indexOfChar] = randChar;
          var newText = charArr.join("");
          element.textContent = newText;
        }
      }, i * speed)
    );
  }

  setTimeout(function () {
    resetGlitch(element);
  }, 500);
}

function resetGlitch(element) {
  if (!glitchLeave) {
    timeouts.forEach(function (timeout) {
      clearTimeout(timeout);
    });
    timeouts = [];
    element.textContent = glitchTexts[element.index - 1];
  }
}

document.querySelectorAll(".glitch-item").forEach(function (element, index) {
  element.index = index + 1;
  element.addEventListener("mouseover", function () {
    applyGlitch(this);
  });
  // element.addEventListener("mouseout", function () {
  //   resetGlitch(this);
  // });
});

 
});
