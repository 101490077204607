export function langInit() {
  const footerLanguageSelector = document.querySelectorAll(".footer-lang li a");

  if (footerLanguageSelector) {
    footerLanguageSelector.forEach((el) => {
      el.addEventListener("click", function () {
        setLangCookie(el.getAttribute("data-lang"));
      });
    });
  }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const currentCookieLang = getCookie("lang");

  const userLangNavigator = navigator.language || navigator.userLanguage;
  const userLang = userLangNavigator.slice(0, 2);

  const supportedLang = ["it", "en"];

  const defaultNS = supportedLang.includes(userLang) ? userLang : "en";

  const setLangCookie = (lang) => {
    var d = new Date();
    d.setTime(d.getTime() + 5184000000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = "lang=" + lang + ";" + expires + ";path=/;";
  };

  if (!currentCookieLang) {
    var langmessages = {
      en: [
        "Do you want to read it in English?",
        "Change to English",
        "No, thanks",
      ],
      it: ["Vuoi leggerlo in italiano?", "Cambia a italiano", "No, grazie"],
    };
    const currentLang = document.documentElement.getAttribute("lang");

    if (currentLang !== userLang) {
      const switchNode = document.querySelectorAll(
        ".footer-lang li a[data-lang=" + userLang + "]"
      );
      if (switchNode.length > 0) {
        const switchLink = switchNode[0].href;
        document.body.insertAdjacentHTML(
          "beforeend",
          '<div class="modal-lang"><a href="#" class="close"><i class="icon-close"></i></a><h4>' +
            langmessages[userLang][0] + 
            '</h4><a href="' +
            switchLink +
            '" class="underline"><div class="modal-lang-switch">' +
            langmessages[userLang][1] +
            '</a> <a href="#">' +
            langmessages[userLang][2] +
            "</a></div></div>"
        );

        document.querySelectorAll("div.modal-lang a").forEach((el) => {
          el.addEventListener("click", function (el) {
            if (el.currentTarget.href.includes("#")) {
              el.preventDefault();
              setLangCookie(currentLang);
              document.querySelectorAll("div.modal-lang")[0].style.display =
                "none";
            } else {
              setLangCookie(defaultNS);
            }
          });
        });
      }
    }
  }
}
